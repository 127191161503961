import api from '@/base/utils/request';

// 图片列表数据
export const imageList = data => {
  return api({
    url: '/admin/cyc_activity/photo/lookOver',
    method: 'post',
    data
  });
};

// 上传图片
export const uploadImg = data => {
  return api({
    url: '/admin/cyc_activity/photo/saveRes',
    method: 'post',
    data
  });
};

// 导入图片
export const resImport = data => {
  return api({
    url: '/admin/cyc_activity/photo/resImport',
    method: 'post',
    data
  });
};

// 删除照片
export const delImages = data => {
  return api({
    url: '/admin/cyc_activity/photo/delRes',
    method: 'post',
    data
  });
};

export const changePhotosOrder = function(ids) {
  return api({
    url: '/admin/cyc_activity/photo/updatePhotoSort',
    method: 'post',
    data: {
      photo_ids: ids
    }
  })
}
