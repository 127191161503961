<template>
  <div class="list-page">
    <el-button class="top-btn" type="default" size="small" @click="goBack">返回
    </el-button>
    <el-button class="top-btn" type="primary" size="small" @click="openMediaSelector = true">上传
    </el-button>
    <template v-if="filterForm.activity_id > 0">
      <el-button type="primary" size="small" v-if="this.$route.params.type === 'select'" @click="handleImport">
        导入图文数据</el-button>
    </template>
    <reminder :textArr="[
      '最新上传的图片将会显示在最前面',
      '点击【管理照片】通过鼠标拖拽图片可以调整排序'
    ]"/>
    <div class="list-content" v-loading="loading">
      <el-button v-if="tbody.length" type="default" size="small" class="switch-btn"
        @click="isAdminImage = !isAdminImage">
        {{isAdminImage?'取消管理':'管理照片'}}</el-button>
      <div style="margin-bottom: 20px" v-if="tbody.length && isAdminImage">
        <el-checkbox v-model="isSelectAll" @change="selectAll">全选</el-checkbox>
        <el-button style="margin-left: 20px" type="danger" size="small" @click="handleDelImages">删除
        </el-button>
        <el-button type="primary" size="small" @click="updateSort">更新排序</el-button>
      </div>
      <p v-if="pageData.total" style="margin-bottom: 20px;">共 {{pageData.total}} 张图片</p>
      <div class="image-wall" v-if="tbody.length && !isAdminImage">
        <div v-for="(item,index) in tbody" class="item-media cursor-pointer">
          <el-image class="item-image"
            :src="fixImageUrl(item.res.type === 'image'? item.res.url:item.res.config.thumbnail)" fit="cover"
            @click="handleImage(item, index)">
          </el-image>
          <div class="video-mask" v-if="item.res.type === 'video'" @click="handlePlay(item.res)">
            <el-image class="video-play" :src="require('@/base/assets/images/media/video-play@2x.png')"
              fit="cover" />
          </div>
        </div>
      </div>
      <el-checkbox-group class="image-wall" v-if="tbody.length && isAdminImage" v-model="selectArr">
        <draggable v-model="tbody" class="drag-list" animation="500" draggable=".item-media" @sort="handleSort">
          <div v-for="(item) in tbody" class="item-media cursor-pointer" :key="item.id">
            <el-image class="item-image" @click.stop="handleSelectImage(item.id)"
                      :src="fixImageUrl(item.res.type === 'image'? item.res.url:item.res.config.thumbnail)" fit="cover">
            </el-image>
            <el-checkbox class="image-select" :label="item.id">{{""}}</el-checkbox>
            <div class="video-mask" v-if="item.res.type === 'video'" @click.stop="handleSelectImage(item.id)">
              <el-image class="video-play" :src="require('@/base/assets/images/media/video-play@2x.png')"
                        fit="cover" />
            </div>
          </div>
        </draggable>
      </el-checkbox-group>
      <el-empty v-if="!loading && !tbody.length" />
    </div>
    <Pagination enable-all :total="pageData.total" :page="pageData.current_page" :page-size="pageData.per_page || 15" :change="changPage" />
    <MediaSelector v-model="openMediaSelector" :multiple="true" :type="['image']"
      @select="handleSelect" :on-uploaded="handleUploaded" />
    <VideoDialog />
  </div>
</template>

<script>
import Pagination from "@/base/components/Default/Pagination";
import MediaSelector from "@/modules/common/components/MediaSelector.vue";
import {
  imageList,
  resImport,
  uploadImg,
  delImages, changePhotosOrder,
} from '../../api/activity-album/upload-images'
import VideoDialog from "@/base/components/Preview/VideoViewer";
import Reminder from '../../../common/components/Reminder'
import draggable from "vuedraggable";

export default {
  data() {
    return {
      loading: false, //加载
      //筛选对象
      filterForm: {
        photo_category_id: this.$route.params.category_id,
        photo_activity_id: this.$route.params.id,
        activity_id: this.$route.params.activity_id,
        page_size: 15,
      },
      pageData: {},
      tbody: [],
      selectArr: [], //勾选的数组id
      isSelectAll: false,
      /* 行内编辑数字验证 */
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      isAdminImage: false,
      isSorted: false,
      openMediaSelector: false,
      openType: "",
      currentForm: {},
      pid_list: [], //上级组织列表,
      openAddCategory: false,
      openActivitySelector: false,
      openEditActivityDialog: false,
    };
  },
  watch: {
    isAdminImage(val) {
      if (!val) {
        this.isSelectAll = false;
        this.selectArr = [];
        // this.isSorted = false
      } else {
        if (this.pageData.total === this.pageData.per_page) return
        this.loading = true
        this.changPage({
          page_size: this.pageData.total,
          page: 1
        })
      }
    },
    selectArr: {
      deep: true,
      handler(val) {
        if (val.length !== this.tbody.length) {
          this.isSelectAll = false;
        } else {
          this.isSelectAll = true;
        }
      },
    },
  },
  methods: {
    handleUploaded(e) {
      this.handleSelect(e)
    },
    updateSort() {
      this.loading = true
      changePhotosOrder(this.tbody.map(el => el.id)).then(res => {
        this.$message.success(res.msg)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    /**
     * 当排序发生变化时触发
     * @param e
     */
    handleSort(e) {
      if (!this.isSorted) this.isSorted = true
    },
    // 获取品牌列表
    getImageList(pageData) {
      this.pageData = {};
      this.loading = true;
      imageList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 导入图文数据
    handleImport() {
      this.$msgbox
        .confirm("是否将活动的图文数据导入", "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          const data = {
            photo_category_id: this.$route.params.category_id,
            photo_activity_id: this.$route.params.id,
            activity_id: this.$route.params.activity_id,
          };
          resImport(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.refreshList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {});
    },

    //操作图片
    handleImage(mediaData, index) {
      if (mediaData.res.type === "image" && !this.isAdminImage)
        this.$previewImage(this.tbody.map(el => el.res.url || el.res.thumbnail), index);
    },
    // 管理选择图片
    handleSelectImage(id) {
      if (this.selectArr.findIndex((item) => item === id) != -1) {
        this.selectArr = this.selectArr.filter((item) => item !== id);
      } else {
        this.selectArr.push(id);
      }
    },
    // 全选操作
    selectAll(val) {
      const all = [];
      if (val) {
        this.tbody.forEach((el) => {
          all.push(el.id);
        });
        this.selectArr = all;
      } else {
        this.selectArr = [];
      }
    },
    // 选择图片上传
    handleSelect(val) {
      const data = {
        photo_category_id: this.filterForm.photo_category_id,
        photo_activity_id: this.filterForm.photo_activity_id,
        config: [...val],
      };
      uploadImg(data)
        .then((res) => {
          this.isAdminImage = false;
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch(() => {});
    },
    // 视频播放
    handlePlay(data) {
      if (!this.isAdminImage) {
        this.$store.commit("media/setIsOpenMediaVideoDialog", true);
        this.$store.commit("media/setDialogDetail", data);
      }
    },
    // 删除照片
    handleDelImages() {
      if (this.selectArr.length) {
        this.loading = true;
        delImages({ id: this.selectArr })
          .then((res) => {
            this.$message.success(res.msg);
            this.selectArr = [];
            this.isSelectAll = false;
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 刷新列表
    refreshList() {
      this.getImageList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const data = { ...this.filterForm, ...e };
      this.getImageList(data);
    },
    // 返回
    goBack() {
      this.$router.push({
        name: "AlbumList",
        params: {
          id: this.$route.params.category_id,
        },
      });
    },
  },
  created() {
    this.getImageList(this.filterForm); //获取列表数据
  },
  components: {
    Reminder,
    Pagination,
    MediaSelector,
    VideoDialog,
    draggable,
  },
};
</script>

<style lang="scss" scoped>
  .drag-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.list-image {
  width: 60px;
  height: 60px;
}
.switch-btn {
  margin-bottom: 20px;
}
.image-wall {
  display: flex;
  flex-wrap: wrap;
  .item-media {
    position: relative;
    .item-image {
      margin: 0 16px 20px 0;
      width: 212px;
      height: 161px;
      border: 1px solid rgb(220, 223, 230);
    }
    .image-select {
      position: absolute;
      top: 10px;
      right: 20px;
    }
    .video-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 212px;
      height: 161px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.4);
      .video-play {
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>
